.left-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 40px;
    color: #EDEDED;
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
    text-align: right;
    cursor: default;
}