.bartender {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
  }
  
  .bar, .bar-out {
    height: 20%;
    width: 100%;
    animation-duration: 0.25s;
    animation-timing-function: ease-in-out;
  }
  
  .bar {
    background-color: goldenrod;
    animation-name: tend;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  .bar-out {
    background-color: goldenrod;
    animation-name: tendoutwards;
    animation-fill-mode: forwards;
    transform: translateX(0%);
  }
  
  @keyframes tend {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0%); }
  }
   
  @keyframes tendoutwards {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
  }
  
  .bar:nth-child(1) { animation-delay: 0ms; }
  .bar:nth-child(2) { animation-delay: 100ms; }
  .bar:nth-child(3) { animation-delay: 200ms; }
  .bar:nth-child(4) { animation-delay: 300ms; }
  .bar:nth-child(5) { animation-delay: 400ms; }
  