.left-column .contact {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10rem;
    padding: 1rem 0;
    color: #FFFFFF;
  }
  
  .left-column .contact h1 {
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: 400;
  }
  
  .left-column .contact ul {
    list-style-type: none;
    padding: 0;
  }
  
  .left-column .contact a {
    color: #FFFFFF;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
  }
  
  .left-column .contact a:hover {
    text-decoration: underline;
    color: darkgoldenrod;
  }
  
  .left-column .contact li {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  
  .left-column .contact li:last-child {
    margin-bottom: 0;
  }
  
  .left-column .contact i {
    margin-right: 10px;
    color: #FFFFFF;
  }
  