body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle 200px at var(--x) var(--y), rgba(255, 255, 255, 0.2), transparent 200px);
  pointer-events: none;
  z-index: 2;
  mix-blend-mode: overlay;
  will-change: background-position;
}

body {
  --x: 50%;
  --y: 50%;
  background-color: #252030;
  font-family: 'Arial', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  position: relative;
}

.container {
  display: flex;
  height: 100vh;
}

/* Media query for mobile layout */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .left-column *,
  .right-column * {
    width: 100%;
    text-align: center;
    border: none !important;
  }
  .contact {
    text-align: right;
  }

}